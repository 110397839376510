import { createGlobalStyle, ThemeProvider as Provider } from "styled-components";

import "font-awesome/css/font-awesome.css";

const theme = {};

const GlobalStyles = createGlobalStyle``;
export default GlobalStyles;
export const Theme = theme;
export const ThemeProvider = Provider;
